import React, { lazy, Suspense } from 'react';

const IntroTextRychleWebStrankyCCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'IntroTextRychleWebStrankyCChLazy' */ './IntroTextRychleWebStrankyCCh'));

function IntroTextRychleWebStrankyCChLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading IntroTextRychleWebStrankyCCh ...</div>}>
                <IntroTextRychleWebStrankyCCh {...props} />
            </Suspense>
        </div>
    );
}

export default IntroTextRychleWebStrankyCChLazy;